var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.groups,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},scopedSlots:_vm._u([{key:"item.isFormed",fn:function(ref){
    var item = ref.item;
return [(item.isFormed === true)?_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" YES ")]):_vm._e(),(item.isFormed === false)?_c('v-chip',{attrs:{"color":"red","text-color":"white"}},[_vm._v(" NO ")]):_vm._e()]}},{key:"item.ownerId",fn:function(ref){
    var item = ref.item;
return [_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getOwnerData(item.ownerId))}})])]}},{key:"item.formedDate",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.formedDate))+" ")])]}},{key:"item.createdDate",fn:function(ref){
    var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdDate))+" ")])]}},{key:"item.participants",fn:function(ref){
    var item = ref.item;
return [_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"black","size":"28","rounded":"","icon":""},on:{"click":function($event){return _vm.showMembersDialog(item.participants)}}},on),[_c('v-icon',[_vm._v("mdi-account-group")])],1)]}}],null,true),model:{value:(item.membersDialog),callback:function ($$v) {_vm.$set(item, "membersDialog", $$v)},expression:"item.membersDialog"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400px"}},[_c('v-card-title',{staticClass:"text-center"},[_c('span',{staticClass:"headline"},[_vm._v("Group members ")])]),_c('v-card-text',[_c('div',{staticClass:"members"},_vm._l((item.participants),function(participant,index){return _c('div',{key:index,staticClass:"member"},[_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("ID:")]),_vm._v(" "+_vm._s(participant.profileId))]),_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("Username:")]),_vm._v(" "+_vm._s(participant.username))]),_c('div',{staticClass:"member-info"},[_c('span',[_vm._v("Email:")]),_vm._v(" "+_vm._s(participant.email))])])}),0)]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.hideMembersDialog(item)}}},[_vm._v("Close")])],1),(item.isSubmitting)?_c('v-progress-linear',{staticClass:"my-4",attrs:{"color":"primary","indeterminate":""}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }